/**
 * Header Layout
 *
 * @file header.js
 */

import $ from "jquery";

const header = (() => {
  const mobileMenu = () => {
    const documentBody = document.querySelector('body');
    const menuIcon = document.querySelector('.mobile-menu');
    const mobileNav = document.querySelector('.mobile-menu-toggle');
    if(menuIcon){
      menuIcon.addEventListener('click', function(e) {
        e.currentTarget.classList.toggle('active');
        mobileNav.classList.toggle('active');
        documentBody.classList.toggle('visible')
      });
      $('.mobile-menu-toggle ul li.main-nav__item').on("click", function(){
        $(this).find(".sub-menu").toggle();
        $(this).toggleClass("active");
      });
    }
  }
  const pageScroll = () => {
    const headerMn = document.querySelector('.o-header');
    document.addEventListener('scroll', function(e) {
      if(window.scrollY >= 30) {
        headerMn.classList.add('fixed')
      } else {
        headerMn.classList.remove('fixed')
      }
    });

      const breadcrumbScrollPosition = document.querySelector(".breadcrumb"); 
      if (breadcrumbScrollPosition){
        const pageToTop = document.querySelector(".up-page");
        const pageToTopValue = breadcrumbScrollPosition.setAttribute("data-top", breadcrumbScrollPosition.offsetTop);
        const pageToTopGetValue = breadcrumbScrollPosition.getAttribute("data-top");


        pageToTop.addEventListener('click', function(e){
          window.scrollTo({top: 0, behavior: 'smooth'});
          pageToTop.classList.add("hide");
        });
        window.onscroll = function (e) { 
          const pageScrollTop = document.documentElement.scrollTop;
          if (pageScrollTop > pageToTopGetValue) {
            breadcrumbScrollPosition.classList.add("fixed");
            pageToTop.classList.remove("hide");
            pageToTop.classList.add("show");
          } else {
            breadcrumbScrollPosition.classList.remove("fixed");
            pageToTop.classList.remove("show");
            pageToTop.classList.add("hide");
          }
        } 
      }
    };
    const search = () =>{
      const searchContent = document.querySelector(".full-page-search");
      const searchBtn = document.querySelector(".search-click-btn");
      const searchTxt = document.getElementById("query");
      const searchBtnMobile = document.querySelector(".mobile-search");
      const documentBody = document.querySelector("html");
      const searchClose = document.querySelector(".full-page-search-close");
      if (searchBtn){
        searchBtn.addEventListener('click', function(e) {
          documentBody.classList.add('hidden');
          searchContent.classList.add('active');
          searchTxt.focus();
        });
        searchBtnMobile.addEventListener('click', function(e) {
          documentBody.classList.add('hidden');
          searchContent.classList.add('active');
          searchTxt.focus();
        });
        searchClose.addEventListener('click', function(e) {
          documentBody.classList.remove('hidden');
          searchContent.classList.remove('active');
          searchTxt.blur();
        });
      }
    }
  const menuHoverBackground = () => {
    const menuHoverBackground = document.querySelector(".full-menu-bg");
    const menuItem = document.getElementsByClassName('main-nav__item');

    for (let i = 0; i < menuItem.length; i += 1) {
      menuItem[i].addEventListener('mouseenter', function (event) {
        menuHoverBackground.classList.toggle('active');
      });
      menuItem[i].addEventListener('mouseleave', function (event) {
        menuHoverBackground.classList.remove('active');
      });
    }
  };
  const aboutCounter = () => {
    if ($('#counter').is(":visible")){
      var a = 0;
      $(window).scroll(function() {

        var oTop = $('#counter').offset().top - window.innerHeight;
        if (a == 0 && $(window).scrollTop() > oTop) {
          $('.counter-value').each(function() {
            var $this = $(this),
              countTo = $this.attr('data-count');
            $({
              countNum: $this.text()
            }).animate({
                countNum: countTo
              },

              {

                duration: 2000,
                easing: 'swing',
                step: function() {
                  $this.text(Math.floor(this.countNum));
                },
                complete: function() {
                  $this.text(this.countNum);
                }

              });
          });
          a = 1;
        }

      });
    }
  }

  const phoneFilter = () => {
    if(!$("input[name='phone']").is(":hidden")){
      $("input[name='phone']").keyup(function() {
          $(this).val($(this).val().replace(/^(\d{3})(\d{3})(\d+)$/, "($1)$2-$3"));
      });
    }
  };

  const customFilter = () => {
    if(!$("select").is(":hidden")){
      var x, i, j, l, ll, selElmnt, a, b, c;
      /*look for any elements with the class "custom-select":*/
      x = document.getElementsByClassName("custom-select");
      l = x.length;
      for (i = 0; i < l; i++) {
        selElmnt = x[i].getElementsByTagName("select")[0];
        ll = selElmnt.length;
        /*for each element, create a new DIV that will act as the selected item:*/
        a = document.createElement("DIV");
        a.setAttribute("class", "select-selected");
        a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
        x[i].appendChild(a);
        /*for each element, create a new DIV that will contain the option list:*/
        b = document.createElement("DIV");
        b.setAttribute("class", "select-items select-hide");
        for (j = 1; j < ll; j++) {
          /*for each option in the original select element,
          create a new DIV that will act as an option item:*/
          c = document.createElement("DIV");
          c.innerHTML = selElmnt.options[j].innerHTML;
          c.addEventListener("click", function(e) {
              /*when an item is clicked, update the original select box,
              and the selected item:*/
              var y, i, k, s, h, sl, yl;
              s = this.parentNode.parentNode.getElementsByTagName("select")[0];
              sl = s.length;
              h = this.parentNode.previousSibling;
              for (i = 0; i < sl; i++) {
                if (s.options[i].innerHTML == this.innerHTML) {
                  s.selectedIndex = i;
                  h.innerHTML = this.innerHTML;
                  y = this.parentNode.getElementsByClassName("same-as-selected");
                  yl = y.length;
                  for (k = 0; k < yl; k++) {
                    y[k].removeAttribute("class");
                  }
                  this.setAttribute("class", "same-as-selected");
                  break;
                }
              }
              h.click();
          });
          b.appendChild(c);
        }
        x[i].appendChild(b);
        a.addEventListener("click", function(e) {
            /*when the select box is clicked, close any other select boxes,
            and open/close the current select box:*/
            e.stopPropagation();
            closeAllSelect(this);
            this.nextSibling.classList.toggle("select-hide");
            this.classList.toggle("select-arrow-active");
          });
      }
      function closeAllSelect(elmnt) {
        /*a function that will close all select boxes in the document,
        except the current select box:*/
        var x, y, i, xl, yl, arrNo = [];
        x = document.getElementsByClassName("select-items");
        y = document.getElementsByClassName("select-selected");
        xl = x.length;
        yl = y.length;
        for (i = 0; i < yl; i++) {
          if (elmnt == y[i]) {
            arrNo.push(i)
          } else {
            y[i].classList.remove("select-arrow-active");
          }
        }
        for (i = 0; i < xl; i++) {
          if (arrNo.indexOf(i)) {
            x[i].classList.add("select-hide");
          }
        }
      }
      /*if the user clicks anywhere outside the select box,
      then close all select boxes:*/
      document.addEventListener("click", closeAllSelect);
    }
  };

  const init = () => {
    menuHoverBackground();
    mobileMenu();
    search();
    pageScroll();
    aboutCounter();
    phoneFilter();
    customFilter();
  };

  return {
    init,
  };
})();

export default header;
