/**
 * Slider Component
 *
 * @file slider.js
 */

import $ from 'jquery';
import Swiper from 'swiper';
import SwiperCore, { Navigation, Pagination, Thumbs, Mousewheel, EffectCoverflow, EffectFade } from 'swiper/core';
SwiperCore.use([Navigation, Pagination, Thumbs, Mousewheel, EffectCoverflow, EffectFade]);
import 'swiper/swiper-bundle.css';


import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const slider = (() => {
  /**
   * fullSlider
   * Full width slider settings
   */
  const fullSlider = () => {
    const swiper = new Swiper('.main-slider', {
      pagination: {
        el: '.swiper-pagination',
      },
    });
  };

  const foldCarousel = () => {
    $('.home-fold-carousel').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      speed:1000,
      autoplay:true,
      autoplaySpeed:2000,
      loop:true,
    });
  };

  const newsSlider = () => {
    const swiperNews = new Swiper('.news-promo-slider', {
      pagination: {
        el: '.swiper-pagination',
      },
    });
  }

  const aboutCenterCarousel = () => {
    const aboutCenterCarousels = new Swiper('.center-three-item', {
      slidesPerView: 4.5,
      spaceBetween: 45,
      centeredSlides: true,
      loop:true,
      navigation: {
        nextEl: ".about-center-next",
        prevEl: ".about-center-prev",
      },
      breakpoints: {  
        0: {
          slidesPerView: 1.5,
          spaceBetween: 20,
        },
        640: {
          slidesPerView: 2.5,
          spaceBetween: 30,
        },
        992: {
          slidesPerView: 3.5,
          spaceBetween: 45,
        },
        1380: {
          slidesPerView: 3.5,
          spaceBetween: 30,
        },
        1680: {
          slidesPerView: 4.5,
          spaceBetween: 45,
        },
      },
    });
  }

  const storyCarousel = () => {
    const aboutCenterCarousels = new Swiper('.story-carousel', {
      slidesPerView: 1,
      spaceBetween: 0,
      loop:true,
      navigation: {
        nextEl: ".story-right-next",
        prevEl: ".story-right-prev",
      },
      pagination: {
        el: ".story-bottom-pagination",
        type: "progressbar",
      },
    });
    const newsTopCarousels = new Swiper('.news-top-carousel', {
      slidesPerView: 1,
      spaceBetween: 0,
      loop:true,
      navigation: {
        nextEl: ".news-right-next",
        prevEl: ".news-right-prev",
      },
      pagination: {
        el: ".news-bottom-pagination",
        type: "progressbar",
      },
    });
  }

  const valueCarousel = () => {
    const aboutCenterCarousels = new Swiper('.values-carousel', {
      slidesPerView: 3.2,
      spaceBetween: 20,
      loop:true,
      navigation: {
        nextEl: ".value-right-next",
        prevEl: ".value-right-prev",
      },
      pagination: {
        el: ".value-top-progressbar",
        type: "progressbar",
      },
      breakpoints: {  
        0: {
          slidesPerView:1.2,
          spaceBetween: 20,
        },
        992: {
          slidesPerView: 2.2,
          spaceBetween: 20,
        },
        1380: {
          slidesPerView: 2.2,
          spaceBetween: 20,
        },
      },
    });
  }

  const lastMinNews = () => {
      if($(".breaking-slides").is(":visible")){

        document.addEventListener('DOMContentLoaded', function() {

        var brSliderList = document.querySelector(".breaking-slides"),
          brSliderItems = document.querySelectorAll(".breaking-news-li"),
          brSliderItemsLength = brSliderItems.length,
          brCurrentIndex = -1;
      
        function prevSlide() {
          brSliderItems[brCurrentIndex].classList.remove('is-active');
          brCurrentIndex = (brCurrentIndex + brSliderItemsLength - 1) % brSliderItemsLength;
          brSliderItems[brCurrentIndex].classList.add('is-active');
        }
      
        function nextSlide() {
          if (brCurrentIndex > 0) brSliderItems[brCurrentIndex].classList.remove('is-active');
          else brSliderItems[0].classList.remove('is-active');
      
          brCurrentIndex = (brCurrentIndex + 1) % brSliderItemsLength;
          brSliderItems[brCurrentIndex].classList.add('is-active');
        }
        setInterval(function(){nextSlide(); }, 2000);
      
        nextSlide();
      
      });
    }
  }

  const init = () => {
    fullSlider();
    foldCarousel();
    newsSlider();
    aboutCenterCarousel();
    storyCarousel();
    valueCarousel();
    lastMinNews();
  };

  return {
    init,
  };
})();

export default slider;
