import browser from './browser';
import { hasTouch, isMobile } from './constants';

/**
 * After Load
 *
 * @file loadPage.js
 */
const loadPage = (() => {
  const documentHtml = document.getElementsByTagName('html')[0];
  // const browserName = browser.getDetail().name.toLowerCase() || '';
  // const browserVersion = browser.getDetail().version || '';

  const classes = [];


  /**
   * JS Load Class
   * js load class for css
   */
  const htmlAddedClasses = () => {
    classes.push('no-js');
    if (hasTouch) {
      classes.push('touch');
    } else {
      classes.push('no-touch');
    }

    if (isMobile) {
      classes.push('mobile');
    } else {
      classes.push('desktop');
    }
    // classes.push(browserName.replace(/ /g, '-'));
    // classes.push(browserVersion);
    classes.push('js');
    classes.shift();
    documentHtml.className = classes.join(' ');
  };

  const init = () => {
    htmlAddedClasses();
  };


  return {
    init,
  };
})();

export default loadPage;
