
import $ from 'jquery';
const accordion = (() => {
  const defaultAccordion = () => {
    const accordionItem = document.getElementsByClassName('js-trigger-accordion');

    for (let i = 0; i < accordionItem.length; i += 1) {
      accordionItem[i].addEventListener('click', function (event) {
        event.currentTarget.classList.toggle('js-active');
        const accordionContent = this.nextElementSibling;
        accordionContent.classList.toggle('js-active');
      });
    }
  };
  const selectFilter = () => {
    $(".filter-li").on("click", function(){
      $(".span-selected").text($(this).text());
    });
  };

  const init = () => {
    defaultAccordion();
    selectFilter();
  };

  return {
    init,
  };
})();

export default accordion;
