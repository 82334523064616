/**
 * Apply Form Module
 *
 * @file applyForm.js
 */
import $ from "jquery";
const applyForm = (() => {
  /**
   *
   * @param {string} name
   */
  const contactInputFile = () => {
    $('.custom-file').on("change", function(){
      $(".cv-location").text($('.custom-file').get(0).files[0].name);
    });
  };

  /**
   * init module
   */
  const init = () => {
    contactInputFile();
  };

  return {
    init,
  };
})();

export default applyForm;
